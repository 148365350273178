import utils from '../../modules/utils';

const inputElectricRecharge = document.querySelector('.js-check-electric');
const inputParking = document.querySelector('.js-parking-select');
const inputProduct = document.querySelector('.js-product-select');
const inputCity = document.querySelector('.js-city-select');

function updateFormParkingTitle(text) {
  const formTitle = document.querySelector('.js-buy__title--parking');
  formTitle.innerHTML = text;
}

function changeTitle(select) {
  const selectedOption = select.options[select.selectedIndex];

  // update title
  updateFormParkingTitle(selectedOption.innerHTML);

  // update url
  const reservationParkingUrlEle = document.querySelector('[data-reservation-parking-url]');
  if (reservationParkingUrlEle) {
    const url = reservationParkingUrlEle.dataset.reservationParkingUrl
      .replace('parkingSlug', selectedOption.dataset.slug);
    window.history.pushState({}, null, url);
  }
}

function hasElectricRecharge() {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get('recarga_electrica') === '1' && inputElectricRecharge.checked) {
    return true;
  }

  return inputElectricRecharge ? inputElectricRecharge.checked : false;
}

function showDetail(detail = null) {
  const img = document.querySelector('.js-image-detail');
  if (img) {
    if (detail) {
      img.src = detail;
      img.classList.remove('u-hidden');
    } else {
      img.src = '';
      img.classList.add('u-hidden');
    }
  }
}

function showDescription(el) {
  if (el) {
    const option = el.selectedIndex ? el.options[el.selectedIndex] : false;
    const element = document.querySelector('.js-description-text');
    if (option && option.dataset.description && option.dataset.description !== '') {
      element.classList.remove('u-hidden');
      element.innerHTML = option.dataset.description;
    } else {
      element.classList.add('u-hidden');
      element.innerHTML = '';
    }
  }
}

function selectParking(event) {
  const optionSelected = event.target.options[event.target.selectedIndex];
  const eventSetZoomMapClick = new CustomEvent('eventSetZoomMapClick', { detail: optionSelected });
  const eventFilterProduct = new CustomEvent('eventFilterProduct', {
    detail: { element: optionSelected.dataset.id, hasElectricRecharge: hasElectricRecharge() },
  });
  document.dispatchEvent(eventSetZoomMapClick);
  document.dispatchEvent(eventFilterProduct);
  if (optionSelected.dataset) {
    showDetail(optionSelected.dataset.image);
  } else {
    showDetail();
  }
  changeTitle(event.target);
}

function selectCity(event, checked = null) {
  if (typeof event === 'object') {
    if (event.target) {
      const optionSelected = event.target.options[event.target.selectedIndex];
      if (
        optionSelected.dataset.lat !== "" &&
        optionSelected.dataset.lng !== ""
      ) {
        const eventSetZoomMapClick = new CustomEvent("eventSetZoomMapClick", {
          detail: optionSelected,
        });
        document.dispatchEvent(eventSetZoomMapClick);
      }
    }
  }

  // clean parking title
  updateFormParkingTitle("");

  let value = event;

  if (event.currentTarget) {
    value = event.currentTarget.value;
  } else if (event.uuid) {
    value = event.uuid;
  }

  if (value) {
    const optionsParking = JSON.parse(inputParking.dataset.parkings);
    const filteredParkings = Object.values(optionsParking).filter((p) => p.city.uuid === value);
    if (inputParking) {
      inputParking.selectedIndex = 0;
      inputParking.disabled = false;

      [...inputParking.options].forEach((o, index) => {
        if (index > 0) {
          o.remove();
        }
      });

      filteredParkings.forEach((val) => {
        const option = document.createElement('option');
        if (val.id === checked) {
          option.selected = true;
        }
        option.text = val.name;
        option.dataset.lat = val.latitude;
        option.dataset.lng = val.longitude;
        option.dataset.zoom = val.zoom ?? 15;
        option.dataset.city = val.city.uuid;
        option.dataset.id = val.uuid;
        option.dataset.electric = val.electricRecharge;
        option.dataset.map = 'item';
        option.dataset.slug = val.slug;
        option.value = val.uuid;
        inputParking.add(option);
      });

      if (inputProduct) {
        inputProduct.selectedIndex = 0;
        inputProduct.disabled = true;
        [...inputProduct.options].forEach((o, index) => {
          if (index > 0) {
            o.remove();
          }
        });
      }

      if (filteredParkings.length === 1) {
        inputParking.selectedIndex = 1;
        selectParking({ target: inputParking });
      }
    }
  }
}

function filterCity(event) {
  const select = document.querySelector('.js-city-select');
  const selectOptions = select.querySelectorAll('option');
  const id = typeof event === 'object' ? event.detail : event;
  selectOptions.forEach((el, index) => {
    if (el.value === id) {
      select.selectedIndex = index;
    }
  });
  selectCity(id);
}

function filterParking(event) {
  const select = document.querySelector('.js-parking-select');
  const optionsParking = JSON.parse(select.dataset.parkings);

  Object.values(optionsParking).forEach((val) => {
    if (val.uuid === event.detail.id) {
      filterCity(val.city.uuid);
      selectCity(val.city.uuid, event.detail.id);
      select.value = val.uuid;
      const el = document.querySelector('[data-selected]')
        ? document.querySelector('[data-selected]').dataset.selected
        : event.detail.id;
      document.dispatchEvent(new CustomEvent('eventFilterProduct', {
        detail: { element: el, hasElectricRecharge: hasElectricRecharge() },
      }));
      if (val.image) {
        showDetail(val.image);
      }
    }
  });
}

function filterProduct(event) {
  const value = typeof event === 'object' ? event.detail.element : event;
  if (value && inputProduct) {
    const optionsProduct = JSON.parse(inputProduct.dataset.products);

    [...inputProduct.options].forEach((o, index) => {
      if (index > 0) {
        o.remove();
      }
    });

    Object.values(optionsProduct).forEach((val) => {
      const condition = event.detail.hasElectricRecharge ? val.electricRecharge === true : true;
      if (val.parking.uuid === value && condition) {
        const option = document.createElement('option');
        option.text = val.name + ' - ' + utils.numberFormat(val.price) + ' ' + (val.currency === 'EUR' ? '€' : val.currency);
        option.dataset.name = val.name;
        option.dataset.description = val.description ?? '';
        option.dataset.price = utils.numberFormat(val.price);
        option.dataset.parking = val.parking.uuid;
        option.dataset.id = val.uuid;
        option.dataset.electric = val.electricRecharge;
        option.value = val.uuid;
        option.dataset.restrictedDays = val.restrictedDays;
        if (val.isTemporary) {
          option.dataset.isTemporary = val.isTemporary;
        }
        if (val.isOneDay) {
          option.dataset.isOneDay = val.isOneDay;
        }
        if (val.isHour) {
          option.dataset.isHour = val.isHour;
        }
        if (val.frequencyData && Object.keys(val.frequencyData).length > 0) {
          option.dataset.frequencyData = JSON.stringify(val.frequencyData);
        }
        inputProduct.add(option);
      }
    });

    if (inputProduct.options.length > 1) {  
      inputProduct.disabled = false;
    } else {
      inputProduct.disabled = true;
    }
    
    showDescription(inputProduct);
  }
}

function setParkingByUrl(slug) {
  const select = document.querySelector('.js-parking-select');
  const selectCity = document.querySelector('.js-city-select');
  const optionsParking = JSON.parse(select.dataset.parkings);
  const parking = Object.values(optionsParking).find((p) => p.slug === slug);
  if (parking) {
    selectCity.value = parking.city.uuid;
    selectCity.dispatchEvent(new CustomEvent('change'));
    select.value = parking.uuid;
    select.dispatchEvent(new CustomEvent('change'));
  }
}

function init() {
  if (inputCity) {
    inputCity.addEventListener('change', (event) => {
      selectCity(event);
      showDescription(inputProduct);
    });
    document.addEventListener('eventSelectCity', filterCity);
  }

  if (inputParking) {
    inputParking.addEventListener('change', (event) => {
      selectParking(event);
      showDescription(inputProduct);
    });
    document.addEventListener('eventSelectParking', filterParking);
  }

  if (inputProduct) {
    document.addEventListener('eventFilterProduct', filterProduct);
    inputProduct.addEventListener('change', (event) => {
      showDescription(event.currentTarget);
    });
  }

  if (inputElectricRecharge) {
    inputElectricRecharge.addEventListener('click', () => {
      const selectedParking = inputParking.value;
      document.dispatchEvent(new CustomEvent('eventChangeElectricOption', {
        detail: { element: selectedParking, hasElectricRecharge: hasElectricRecharge() },
      }));
    });
  }

  if (inputParking) {
    const pathnameSplitted = window.location.pathname.split('/');
    const parkingSlug = pathnameSplitted.pop();
    setParkingByUrl(parkingSlug);
  }
  document.addEventListener('eventChangeElectricOption', filterProduct);
}

export default init();
