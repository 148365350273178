import { Application } from '@hotwired/stimulus';

/** legacy components */
import './js/legacy/components/form/form.component';

/** legacy modules */
import './js/legacy/modules/datepicker';
import './js/legacy/modules/pixel';

/** legacy controllers */
import './js/legacy/controllers/error';
import './js/legacy/controllers/dropdown';
import './js/legacy/controllers/parking';
import './js/legacy/controllers/contact';
import './js/legacy/controllers/payment';
import './js/legacy/controllers/edit-reservation';
import './js/legacy/controllers/sell';
import './js/legacy/controllers/external-contract';
import './js/legacy/controllers/homepage-search-parking';
import './js/legacy/controllers/search-parking';
import './js/legacy/controllers/search-parking-map';
import './js/legacy/controllers/tabs';
import './js/legacy/controllers/season';
import './js/legacy/controllers/home';
import './js/legacy/controllers/login';
import './js/legacy/controllers/password-reset';
import './js/legacy/register';
import './js/legacy/user-area';

import Success from './js/legacy/components/reservation/success'
import DownloadLinks from './js/legacy/controllers/download-links_controller';

import { init as initModal } from './js/legacy/modal';
initModal();

const stimulus = Application.start();
stimulus.register('success', Success);
stimulus.register('download-links', DownloadLinks);
